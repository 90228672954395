import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img style={{
      width: '100%',
      boxShadow: 'rgba(0,0,0,0.15) 0px 32px 32px 0px, rgba(0,0,0,0.15) 0px 16px 16px 0px, rgba(0,0,0,0.15) 0px 8px 8px 0px, rgba(0,0,0,0.15) 0px 4px 4px 0px',
      marginTop: '4rem',
      marginBottom: '2rem'
    }} src="kbd2600.jpg" />
    <div style={{
      marginBottom: '2rem'
    }}>
      <h4>KBD2600</h4>
    </div>
    <p>{`The KBD2600 is an Atari inspired 60%, featuring a milled aluminum body, stainless steel weight, and customizable front panels. The board features a gasket mounting system for a softer, more enjoyable typing experience.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      