import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
Hey 👋
    </Text>
    <p>{`I'm Carson, and out of the 1.84 billion websites on the internet, you've managed to find mine! Here you'll find information about me, information about my projects, weekly status updates, and any documentation relating to my work.`}</p>
    <p>{`If you want to hang out with me, I stream design/programming work most Saturdays at 12:00 PM Pacific Time on `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UChTQaAiwVOZLqh3gsNPd3Uw"
      }}>{`Youtube`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.twitch.tv/carsonwrong"
      }}>{`Twitch`}</a>{`. You're also more than welcome to come hang out in my `}<a parentName="p" {...{
        "href": "https://discord.gg/tfCnAxaYtR"
      }}>{`Community Discord Server`}</a>{` which is a great place to get help, behind the scenes information, and more.`}</p>
    <p>{`If for any reason you need to contact me, feel free to shoot me an email at `}<inlineCode parentName="p">{`carson@carsonwright.me`}</inlineCode>{`, or send me a message on Discord at `}<inlineCode parentName="p">{`Carson Wrong#0001`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      