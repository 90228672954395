import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Other Stuff" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about"
        }}>{`😃 - About`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/lookbook"
        }}>{`👀 - Lookbook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contact"
        }}>{`📞 - Contact`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/collaborations"
        }}>{`🧑‍🤝‍🧑 - Collaborations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/commissions"
        }}>{`💸 - Commissions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/content-creation"
        }}>{`📷 - Content Creation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/open-source"
        }}>{`📚 - Open Source`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/faq"
        }}>{`🤔 - FAQ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/policies"
        }}>{`📝 - Policies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/support"
        }}>{`🆘 - Support`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/resources/docs"
        }}>{`ℹ️ - Docs`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      